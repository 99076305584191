import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GrowthSlotsState, growthSlotsTabs, SlotsRoute } from '@features/slots/state/growth-slots.state';
import {
  AirportViewSlotDayDto,
  AirportViewSlotDto,
  AirportViewSlotView,
  CurfewAirportDto,
  CurfewAirportView,
  CurfewDto,
  SlotProfile
} from '@features/slots/slots.models';
import { AirportDropdownOption } from '@features/settings/airports/airports.model';
import { BreadcrumbsData } from '@shared/components/breadcrumbs/breadcrumbs.model';
import { GrowthSlotsMainViewModel } from '@features/slots/slots-main/slots-main.component';
import { selectAirportDropdownOnlyAirportsOptions } from '@features/settings/airports/state/airports.selectors';
import { SelectItem } from '@shared/models/select-item.interface';
import { AirportViewModel } from '@features/slots/slots-airport-view/slots-airport-view.component';
import { DropdownFilter } from '@shared/components/flyinglines/flyinglines.models';
import { SlotsCurfewsViewModel } from '@features/slots/slots-curfews/slots-curfews.component';
import { GrowthSlotsViewModel } from '@features/slots/slot-details/slot-details.component';
import { SlotsMainService } from '@features/slots/services/slots-main.service';
import { selectIsReadOnlyUser } from '@state/app.selectors';

export const selectGrowthSlotsState = createFeatureSelector<GrowthSlotsState>('growth-slots');

export const selectGrowthSlotsIsLocalTimeZone = createSelector(
  selectGrowthSlotsState,
  (state): boolean => state?.isLocalTimeZone
);

export const selectSlotProfiles = createSelector(selectGrowthSlotsState, (state): SlotProfile[] => state.slotProfiles);

export const selectGrowthSlotsMainSelectedTab = createSelector(
  selectGrowthSlotsState,
  (state): SelectItem<SlotsRoute> => state.selectedTab
);

export const selectGrowthSlotsMainIsMultipleAirportsDropdownShown = createSelector(
  selectGrowthSlotsMainSelectedTab,
  (selectedTab): boolean => !['curfews-additional-constraints'].includes(selectedTab.value)
);

export const selectGrowthSlotsSlotName = createSelector(selectGrowthSlotsState, (state): string => state.slotName);

export const selectGrowthSlotsSelectedTabIndex = createSelector(
  selectGrowthSlotsMainSelectedTab,
  (selectedTab): number => growthSlotsTabs.findIndex((tab) => tab.value === selectedTab.value)
);

export const selectGrowthSlotsBreadcrumbsData = createSelector(
  selectGrowthSlotsSlotName,
  (slotName): BreadcrumbsData => ({
    backLabel: 'Growth slots',
    backRoute: ['slots'],
    pageLabel: slotName
  })
);

export const selectDropdownFilterAirports = createSelector(
  selectGrowthSlotsState,
  (state): string[] | undefined => state.dropdownFilterAirports
);

export const selectSelectedAirports = createSelector(
  selectDropdownFilterAirports,
  selectAirportDropdownOnlyAirportsOptions,
  (dropdownFilterAirports, airportDropdownOptions): AirportDropdownOption[] => {
    return airportDropdownOptions.filter((option) => dropdownFilterAirports.includes(option.code));
  }
);

export const selectDropdownFilter = createSelector(
  selectDropdownFilterAirports,
  selectAirportDropdownOnlyAirportsOptions,
  (airports, airportDropdownOptions): DropdownFilter => {
    const options = airportDropdownOptions.filter((option) => airports.includes(option.code));
    return { operator: 'OR', options };
  }
);
export const selectAirportFromAirports = createSelector(
  selectAirportDropdownOnlyAirportsOptions,
  selectGrowthSlotsState,
  (dropdownOptions, state): string | undefined =>
    state.selectedAirportFromAirports ?? (dropdownOptions?.length ? dropdownOptions[0].code : undefined)
);

export const selectGrowthSlotsMainFilterOptions = createSelector(
  selectGrowthSlotsMainIsMultipleAirportsDropdownShown,
  selectGrowthSlotsIsLocalTimeZone,
  (isMultipleAirportDropdownShown, isLocalTimeZone) => {
    return {
      isMultipleAirportDropdownShown,
      isLocalTimeZone
    };
  }
);

export const selectGrowthSlotsMainViewModel = createSelector(
  selectGrowthSlotsBreadcrumbsData,
  selectGrowthSlotsSelectedTabIndex,
  selectGrowthSlotsMainFilterOptions,
  selectAirportDropdownOnlyAirportsOptions,
  selectSelectedAirports,
  (
    breadcrumbsData,
    selectedTabIndex,
    filterOptions,
    airportsForDropdown,
    selectedAirports
  ): GrowthSlotsMainViewModel => {
    return {
      breadcrumbsData,
      selectedTabIndex,
      ...filterOptions,
      airportsForDropdown,
      selectedAirports
    };
  }
);

export const selectAirportViewSlot = createSelector(
  selectGrowthSlotsState,
  (state): AirportViewSlotDto => state.airportSlot
);

export const selectAirportViewSlotView = createSelector(selectAirportViewSlot, (airportSlot): AirportViewSlotView => {
  if (!airportSlot) return undefined;
  return {
    ...airportSlot,
    days: airportSlot?.days.map((day: AirportViewSlotDayDto) => {
      return {
        ...day,
        arrival_window: {
          ...day.arrival_window,
          open_minutes_label: convertNumberOfMinutesToTimeString(day.arrival_window.open_minutes),
          close_minutes_label: convertNumberOfMinutesToTimeString(day.arrival_window.close_minutes),
          close_is_next_day:
            (day.arrival_window.open_minutes === 0 ? 1440 : day.arrival_window.open_minutes) >
            (day.arrival_window.close_minutes === 0 ? 1440 : day.arrival_window.close_minutes)
        },
        departure_window: {
          ...day.departure_window,
          open_minutes_label: convertNumberOfMinutesToTimeString(day.departure_window.open_minutes),
          close_minutes_label: convertNumberOfMinutesToTimeString(day.departure_window.close_minutes),
          close_is_next_day:
            (day.departure_window.open_minutes === 0 ? 1440 : day.departure_window.open_minutes) >
            (day.departure_window.close_minutes === 0 ? 1440 : day.departure_window.close_minutes)
        }
      };
    })
  };
});

function convertNumberOfMinutesToTimeString(minutes: number): string {
  let result = '0000';
  if (minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    result = `${hours > 9 ? hours : '0' + hours}${mins > 9 ? mins : '0' + mins}`;
  }
  return result;
}

export const selectTimeZoneDifference = createSelector(
  selectAirportViewSlotView,
  selectGrowthSlotsIsLocalTimeZone,
  (airportViewSlot, isLocalTimeZone): string => {
    if (!airportViewSlot) return null;
    if (isLocalTimeZone) {
      return null;
    } else {
      const time = Math.round(airportViewSlot.utc_offset / 60);
      if (time < 0) {
        return '+' + time;
      } else if (time > 0) {
        return '-' + time;
      } else {
        return '0';
      }
    }
  }
);

export const selectAirportViewHours = createSelector(selectTimeZoneDifference, (timezoneDifference): string[] => {
  return SlotsMainService.getTableHeaderHours(timezoneDifference);
});

export const selectDisplayUtcOffset = createSelector(selectAirportViewSlotView, (airportViewSlot): string =>
  airportViewSlot?.utc_offset ? `UTC +${airportViewSlot.utc_offset / 60}` : undefined
);

export const selectAirportViewModel = createSelector(
  selectAirportViewSlotView,
  selectGrowthSlotsIsLocalTimeZone,
  selectTimeZoneDifference,
  selectAirportViewHours,
  selectDisplayUtcOffset,
  (airportViewSlot, isLocalTimeZone, timeZoneDifference, hours, displayUtcOffset): AirportViewModel => {
    return {
      airportViewSlot,
      isLocalTimeZone,
      timeZoneDifference,
      hours,
      displayUtcOffset
    };
  }
);

function formatPropertyToTimeString(property: number, isNextDay: boolean): string {
  const formatedProperty = convertNumberOfMinutesToTimeString(property);
  return isNextDay ? `${formatedProperty}+1` : formatedProperty;
}

export function checkIfTwentyFourHourIsToggled(curfew: CurfewDto): boolean {
  return (
    curfew.arrival_window &&
    curfew.departure_window &&
    convertNumberOfMinutesToTimeString(curfew.arrival_window.open_minutes) === '0000' &&
    convertNumberOfMinutesToTimeString(curfew.arrival_window.close_minutes) === '2355' &&
    convertNumberOfMinutesToTimeString(curfew.departure_window.open_minutes) === '0000' &&
    convertNumberOfMinutesToTimeString(curfew.departure_window.close_minutes) === '2355'
  );
}

export const selectNotSavedAirportCurfew = createSelector(
  selectGrowthSlotsState,
  (state): CurfewAirportDto => state.airportCurfewDraft
);

export const selectCurfews = createSelector(
  selectGrowthSlotsState,
  (state): CurfewAirportDto[] => state.airportCurfews
);

export const selectCurfewViews = createSelector(
  selectCurfews,
  selectNotSavedAirportCurfew,
  (airportCurfews, notSavedAirportCurfew): CurfewAirportView[] => {
    return (
      airportCurfews
        ?.map((curfewAirportDto) => {
          return curfewAirportDto.curfews.map((curfew, index): CurfewAirportView => {
            return {
              ...curfewAirportDto,
              uid: `${curfewAirportDto.airport_code}-${curfew.days.join(',')}`,
              curfews: undefined,
              curfew,
              first_departure: curfew.departure_window?.open_minutes
                ? formatPropertyToTimeString(
                    curfew.departure_window.open_minutes,
                    curfew.departure_window.open_is_next_day
                  )
                : undefined,
              last_departure: curfew.departure_window?.close_minutes
                ? formatPropertyToTimeString(
                    curfew.departure_window.close_minutes,
                    curfew.departure_window.close_is_next_day
                  )
                : undefined,
              first_arrival: curfew.arrival_window?.open_minutes
                ? formatPropertyToTimeString(curfew.arrival_window.open_minutes, curfew.arrival_window.open_is_next_day)
                : undefined,
              last_arrival: curfew.arrival_window?.close_minutes
                ? formatPropertyToTimeString(
                    curfew.arrival_window.close_minutes,
                    curfew.arrival_window.close_is_next_day
                  )
                : undefined,
              row_disabled: checkIfTwentyFourHourIsToggled(curfew),
              is_parent: index === 0,
              is_new_row:
                notSavedAirportCurfew?.airport_code === curfewAirportDto.airport_code &&
                index === curfewAirportDto.curfews.length - 1
            };
          });
        })
        ?.flat() ?? []
    );
  }
);

export const selectCurfewsViewModel = createSelector(
  selectCurfewViews,
  selectIsReadOnlyUser,
  (curfewAirports, isUserReadOnly): SlotsCurfewsViewModel => ({
    curfewAirports,
    isUserReadOnly
  })
);

// Slot details

export const selectSlotsDetailsStateIsPreview = createSelector(selectGrowthSlotsState, (state): boolean => {
  return state.slotsDetailsAirportRowDraft === undefined;
});

export const selectSlotDetailsEditedAirport = createSelector(
  selectGrowthSlotsState,
  (state): string | undefined => state.slotsDetailsAirportRowDraft
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectSlotsDetailsIsEditingState = (selectedAirport: string) =>
  createSelector(selectSlotDetailsEditedAirport, (editedAirport): boolean => {
    return editedAirport === selectedAirport;
  });

export const selectSlotsDetailsContextMenuConfig = createSelector(selectSlotsDetailsStateIsPreview, (isPreview) => {
  return {
    isPreview,
    isCloningDisabled: false
  };
});

export const selectSlotsDetailsAirports = createSelector(selectGrowthSlotsState, (state) => state.slotsDetailsAirports);

export const selectNewDraftRowSlots = createSelector(
  selectSlotsDetailsAirports,
  selectSlotDetailsEditedAirport,
  (slotAirports, editedAirport) => {
    const allEditedAirportSlots = slotAirports?.find((airport) => airport.airport_code === editedAirport)?.slots;
    if (!allEditedAirportSlots) return undefined;
    return allEditedAirportSlots[allEditedAirportSlots.length - 1];
  }
);

export const selectSlotsDetailsViewModel = createSelector(
  selectSlotsDetailsAirports,
  selectSlotsDetailsStateIsPreview,
  selectIsReadOnlyUser,
  (airports, isPreview, isUserReadOnly): GrowthSlotsViewModel => {
    return {
      airports,
      isPreview,
      isUserReadOnly
    };
  }
);
