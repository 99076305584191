import { Injectable } from '@angular/core';
import { AirportFilterOptions } from '../../settings/airports/airports.model';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';
import {
  CurfewAirportDto,
  CurfewAirportView,
  CurfewDto,
  CurfewOverride,
  CurfewWindowDto
} from '@features/slots/slots.models';
import { checkIfTimeWithSymbolIsValidFormat, convertTimeStringToMinutes } from '@utils/time.utils';
import { areArraysEqualWithSort } from '@utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SlotsMainService {
  private addCurfewOverrideSubject = new Subject<string>();
  addCurfewOverride$ = this.addCurfewOverrideSubject.asObservable();

  static getTableHeaderHours(timeZoneDifference: string | null): string[] {
    const { symbol, timeDifference } = SlotsMainService.parseTimeZoneDifference(timeZoneDifference);
    return Array.from({ length: 25 }, (_, i) => {
      const adjustedHour = SlotsMainService.adjustHourForTimeZone(i, symbol, timeDifference);
      return `${adjustedHour}h`;
    });
  }

  private static parseTimeZoneDifference(timeZoneDifference: string | null): {
    symbol: '+' | '-' | undefined;
    timeDifference: number;
  } {
    if (!timeZoneDifference) return { symbol: undefined, timeDifference: 0 };
    const symbol = timeZoneDifference.slice(0, 1) as '+' | '-';
    const timeDifference = +timeZoneDifference.slice(1, 3);
    return { symbol, timeDifference };
  }

  private static adjustHourForTimeZone(hour: number, symbol: '+' | '-' | undefined, timeDifference: number): number {
    if (!symbol) return hour;
    let adjustedHour = symbol === '+' ? hour + timeDifference : hour - timeDifference;
    if (adjustedHour > 24) {
      adjustedHour -= 24;
    } else if (adjustedHour <= 0) {
      adjustedHour += 24;
    }
    return adjustedHour;
  }

  mapQueryParamsToFilterOptions(queryParams: Params): AirportFilterOptions {
    return new AirportFilterOptions(
      queryParams.selectedAirports ? queryParams.selectedAirports.toString() : '',
      queryParams.selectedCities ? queryParams.selectedCities.toString() : '',
      queryParams.selectedCountries ? queryParams.selectedCountries : '',
      queryParams.selectedCoordLevels ? queryParams.selectedCoordLevels : '',
      queryParams.searchSwitch ? queryParams.searchSwitch : ''
    );
  }

  isArrivalDepartureValid(data: CurfewAirportView | CurfewOverride, property: string): boolean {
    const isArrivalProperty = property.includes('arrival');
    const arrivalDepartureSuffix = isArrivalProperty ? 'arrival' : 'departure';
    const noAirportCodeOpenSuffix = isArrivalProperty ? 'arrival_open' : 'departure_open';
    const noAirportCodeCloseSuffix = isArrivalProperty ? 'arrival_close' : 'departure_close';
    const openKey = 'airport_code' in data ? `first_${arrivalDepartureSuffix}` : noAirportCodeOpenSuffix;
    const closeKey = 'airport_code' in data ? `last_${arrivalDepartureSuffix}` : noAirportCodeCloseSuffix;
    return this.checkIsArrivalDepartureValid(data[openKey], data[closeKey]);
  }

  private checkIsArrivalDepartureValid(firstValue: string, lastValue: string): boolean {
    // first arrival/departure can't be greater than last
    const firstValueConverted = convertTimeStringToMinutes(firstValue, firstValue?.includes('+1'));
    const lastValueConverted = convertTimeStringToMinutes(lastValue, lastValue?.includes('+1'));
    return (
      (!firstValueConverted || !lastValueConverted || firstValueConverted < lastValueConverted) &&
      checkIfTimeWithSymbolIsValidFormat(firstValue) &&
      checkIfTimeWithSymbolIsValidFormat(lastValue)
    );
  }

  isRowDataValid(data: CurfewAirportView): boolean {
    return (
      this.checkIsArrivalDepartureValid(data.first_arrival, data.last_arrival) &&
      this.checkIsArrivalDepartureValid(data.first_departure, data.last_departure) &&
      (data.is_new_row || (data.concurrent_take_off >= 0 && data.concurrent_arrivals >= 0))
    );
  }

  static updateCurfewAirportIfNecessary(
    curfewAirport: CurfewAirportDto,
    action: { updatedCurfewView: CurfewAirportView; value: string; property: string }
  ): CurfewAirportDto {
    if (curfewAirport.airport_code !== action.updatedCurfewView.airport_code) {
      return curfewAirport;
    }
    return {
      ...curfewAirport,
      curfews: curfewAirport.curfews.map((curfew) => SlotsMainService.updateCurfewIfNecessary(curfew, action))
    };
  }

  static updateCurfewIfNecessary(
    curfew: CurfewDto,
    action: { updatedCurfewView: CurfewAirportView; value: string; property: string }
  ): CurfewDto {
    if (!areArraysEqualWithSort(curfew.days, action.updatedCurfewView.curfew.days)) {
      return curfew;
    }
    return {
      ...curfew,
      arrival_window: action.property.includes('arrival')
        ? SlotsMainService.updateWindow(curfew.arrival_window, action, ['first_arrival', 'last_arrival'])
        : curfew.arrival_window,
      departure_window: action.property.includes('departure')
        ? SlotsMainService.updateWindow(curfew.departure_window, action, ['first_departure', 'last_departure'])
        : curfew.departure_window
    };
  }

  static updateWindow(
    window: CurfewWindowDto,
    action: { updatedCurfewView: CurfewAirportView; value: string; property: string },
    [firstProperty, lastProperty]: ['first_arrival', 'last_arrival'] | ['first_departure', 'last_departure']
  ): CurfewWindowDto {
    return {
      ...window,
      open_minutes: action.property === firstProperty ? convertTimeStringToMinutes(action.value) : window.open_minutes,
      close_minutes: action.property === lastProperty ? convertTimeStringToMinutes(action.value) : window.close_minutes,
      open_is_next_day: action.property === firstProperty ? action.value.includes('+1') : window.open_is_next_day,
      close_is_next_day: action.property === lastProperty ? action.value.includes('+1') : window.close_is_next_day
    };
  }
}
