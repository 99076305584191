import { createAction, props } from '@ngrx/store';
import {
  AddCurfew,
  AirportViewSlotDayView,
  AirportViewSlotDto,
  CurfewAirportDto,
  CurfewAirportView,
  CurfewDto,
  GrowthSlotsDetailsAirport,
  GrowthSlotsDetailsSlots,
  SlotProfile
} from '@features/slots/slots.models';
import { SlotsRoute } from '@features/slots/state/growth-slots.state';
import { SelectItem } from '@shared/models/select-item.interface';
import { AirportDropdownOption } from '@features/settings/airports/airports.model';
import { SelectedAirportSlotItem } from '@features/slots/slot-details/slot-details.component';

export const slotsProfilesPageInitialized = createAction('[Growth Slots Profiles] Page initialized');

export const apiGrowthSlotsProfilesFetchSuccess = createAction(
  '[Growth Slots API] Profiles fetch success',
  props<{ slotsProfiles: SlotProfile[] }>()
);

export const growthSlotsClickedProfile = createAction(
  '[Growth Slots Profiles] Clicked profile',
  props<{ profile: SlotProfile }>()
);
export const apiGrowthSlotsNewProfileAdded = createAction(
  '[Growth Slots API] New profile added',
  props<{ profile: SlotProfile }>()
);
export const apiGrowthSlotsProfileRemoved = createAction(
  '[Growth Slots API] Profile deleted',
  props<{ profileId: number }>()
);

export const growthSlotsClickedProfileSuccess = createAction(
  '[Growth Slots Profiles] Clicked profile success',
  props<{ profile: SlotProfile }>()
);

export const growthSlotsMainPageInitialized = createAction('[Growth Slots Main] initialized');
export const growthSlotsMainPageInitializedSuccess = createAction(
  '[Growth Slots Main] initialized success',
  props<{
    currentTab: SelectItem<SlotsRoute>;
    selectedTabIndex: number;
    slotName: string | undefined;
    isLocalTimeZone: boolean | undefined;
    dropdownFilterAirports: string[] | undefined;
  }>()
);
export const growthSlotsMainPageDestroyed = createAction('[Growth Slots Main] destroyed');
export const growthSlotsMainFiltersParamsChanged = createAction(
  '[Growth Slots Main] Filters params changed',
  props<{
    isLocalTimeZone: boolean | undefined;
    dropdownFilterAirports: string[] | undefined;
  }>()
);

export const growthSlotsMainPageChangedIsLocalTimeZone = createAction(
  '[Growth Slots Main Page] Changed is local time zone toggle',
  props<{ isLocalTimeZone: boolean }>()
);

export const growthSlotsMainMultipleAirportsSelectionChanged = createAction(
  '[Growth Slot Multiple Airport Dropdown] Changed multiple airports selection items',
  props<{ dropdownFilter: AirportDropdownOption[] }>()
);

export const growthSlotsMainDropdownSelectionRemoved = createAction(
  '[Growth Slots Main] Dropdown selection removed',
  props<{ dropdownOption: AirportDropdownOption }>()
);

export const growthSlotsMainDropdownSelectionRemovedSuccess = createAction(
  '[Growth Slots Main] Dropdown selection removed success',
  props<{ dropdownFilter: AirportDropdownOption[] }>()
);

export const growthSlotsMainTabChanged = createAction(
  '[Growth Slots Main] Tab Changed',
  props<{ newTab: SelectItem<SlotsRoute> }>()
);

export const growthSlotsMainTabChangedSuccess = createAction(
  '[Growth Slots Main] Tab Changed Success',
  props<{ newTab: SelectItem<SlotsRoute> }>()
);

export const apiGrowthSlotsSlotsFetchSuccess = createAction(
  '[Growth Slots API] Slots fetch success',
  props<{ airportSlot: AirportViewSlotDto }>()
);

export const slotsAirportViewZoneChanged = createAction(
  '[Growth Slots Airport View] Zone changed',
  props<{
    zone: number;
    slotType: 'arrival' | 'departure';
    i: number;
    day: AirportViewSlotDayView;
    airportCode: string;
  }>()
);

export const apiGrowthSlotsAirportViewSlotChanged = createAction(
  '[Growth Slots API] Slot changed',
  props<{
    zone: number;
    slotType: 'arrival' | 'departure';
    i: number;
    day: AirportViewSlotDayView;
    airportCode: string;
  }>()
);

export const apiGrowthSlotsAirportViewSlotChangedFailed = createAction('[Growth Slots API] Slot changed failed');

// Curfews

export const growthSlotsCurfewsPageInitialized = createAction('[Growth Slots Curfews] Page initialized');
export const apiGrowthSlotsAirportCurfewsFetched = createAction(
  '[Growth Slotes API] Airport curfews fetched',
  props<{ airportCurfews: CurfewAirportDto[] }>()
);

export const growthSlotsCurfewsDayClicked = createAction(
  '[Growth Slots Curfews] Day clicked',
  props<{ airportRowIndex: number; day: number }>()
);

export const growthSlotsCurfewsDayClickedOnDraftRow = createAction(
  '[Growth Slots Curfews] Day clicked on draft row',
  props<{ updatedCurfew: CurfewAirportView; day: number }>()
);

export const apiGrowthSlotsCurfewsDayChanged = createAction(
  '[Growth Slots API] Curfews day changed',
  props<{ airportCurfews: AirportViewSlotDto }>()
);

export const growthSlotsCurfewsAddNewRowClicked = createAction(
  '[Growth Slots Curfews] Add new row clicked',
  props<{ airportCode: string }>()
);

export const growthSlotsCurfewsSaveRowClicked = createAction(
  '[Growth Slots Curfews] Save row clicked',
  props<{ updatedCurfewView: CurfewAirportView }>()
);

export const growthSlotsCurfewsCancelEditingRowClicked = createAction(
  '[Growth Slots Curfews] Cancel editing row clicked',
  props<{ cancelledCurfewView: CurfewAirportView }>()
);

export const apiGrowthSlotsCurfewsUpdated = createAction(
  '[Growth Slots API] Curfews updated',
  props<{ airportCurfews: AirportViewSlotDto }>()
);

export const growthSlotsCurfewsArrivalDepartureChangedOnDraftRow = createAction(
  '[Growth Slots Curfews] Arrival departure changed on draft row',
  props<{ updatedCurfewView: CurfewAirportView; value: string; property: string }>()
);

export const growthSlotsCurfewsArrivalDepartureChanged = createAction(
  '[Growth Slots Curfews] Arrival departure changed',
  props<{ updatedCurfewView: CurfewAirportView; value: string; property: string }>()
);

export const growthSlotsCurfewsConcurrentValueChanged = createAction(
  '[Growth Slots Curfews] Concurrent value changed',
  props<{ updatedCurfewView: CurfewAirportView; value: string; property: string }>()
);
export const growthSlotsCurfewsToggle24hClicked = createAction(
  '[Growth Slots Curfews] Toggle 24h clicked',
  props<{ updatedCurfewView: CurfewAirportView }>()
);

export const growthSlotsSlotsDetailsInitialized = createAction('[Growth Slots Slots Details] Initialized');

export const apiGrowthSlotsDetailsAirportsFetchSuccess = createAction(
  '[Growth Slots API] Details airports fetch success',
  props<{ growthSlotsDetailsAirports: GrowthSlotsDetailsAirport[] }>()
);

export const growthSlotsSlotDetailsRelaxAllDeparturesClicked = createAction(
  '[Growth Slots Slots Details] Relax all departures clicked',
  props<{ slotItem: SelectedAirportSlotItem }>()
);

export const growthSlotsSlotDetailsRestricsAllDeparturesClicked = createAction(
  '[Growth Slots Slots Details] Restrict all departures clicked',
  props<{ slotItem: SelectedAirportSlotItem }>()
);

export const growthSlotsSlotDetailsRelaxAllArrivalsClicked = createAction(
  '[Growth Slots Slots Details] Restrics all arrivals clicked',
  props<{ slotItem: SelectedAirportSlotItem }>()
);

export const growthSlotsSlotDetailsRestrictAllArrivalsClicked = createAction(
  '[Growth Slots Slots Details] Restrict all arrivales clicked',
  props<{ slotItem: SelectedAirportSlotItem }>()
);

export const apiGrowthSlotsSlotUpdatedForUpdate = createAction(
  '[Growth Slots API] Slot updated requiring reload',
  props<{ airportSlot: AirportViewSlotDto }>()
);

export const apiGrowthSlotsSlotUpdated = createAction(
  '[Growth Slots API] Slot zone updated',
  props<{ curfew: Partial<AddCurfew>; slotType: 'arrival' | 'departure'; airport: string }>()
);

export const apiGrowthSlotsSlotBulkUpdated = createAction(
  '[Growth Slots API] Slot bulk updated',
  props<{ curfewDto: CurfewDto }>()
);

export const growthSlotsSlotDetailsExistingDaysChanged = createAction(
  '[Growth Slots Slots Details] Existing days changed',
  props<{ days: number[]; airport: string; slots: GrowthSlotsDetailsSlots }>()
);

export const growthSlotsSlotDetailsNewRowDaysChanged = createAction(
  '[Growth Slots Slots Details] New row days changed',
  props<{ days: number[]; airport: string; slots: GrowthSlotsDetailsSlots }>()
);

export const growthSlotsSlotDetailsExistingZonesChanged = createAction(
  '[Growth Slots Slots Details] Existing zones changed',
  props<{
    curfew: Partial<AddCurfew>;
    zone: number;
    slotType: 'arrival' | 'departure';
    i: number;
    airport: string;
  }>()
);

export const growthSlotsSlotDetailsNewRowZonesChanged = createAction(
  '[Growth Slots Slots Details] New row zones changed',
  props<{ airport: string; curfew: Partial<AddCurfew> }>()
);

export const growthSlotsSlotDetailsCloneToAll = createAction(
  '[Growth Slots Slots Details] Clone to all',
  props<{ selectedAirportItem: SelectedAirportSlotItem }>()
);

export const growthSlotsSlotDetailsClickedAddNewRow = createAction(
  '[Growth Slots Slots Details] Clicked add new row',
  props<{ airport: SelectedAirportSlotItem }>()
);

export const growthSlotsSlotDetailsAddNewRowCancel = createAction(
  '[Growth Slots Slots Details] Clicked cancel adding new row'
);

export const growthSlotsSlotDetailsAddNewRowSave = createAction(
  '[Growth Slots Slots Details] Clicked save adding new row'
);

export const apiGrowthSlotsSlotDetailsAddedNewRow = createAction('[Growth Slots API] Added new row');

export const schedulPlanGrowthSlotsModalDisplayed = createAction(
  '[Growth Slots Modal] Modal displayed',
  props<{ growthSlotProfileId: number; airport: string }>()
);

export const schedulPlanGrowthSlotsModalChanged = createAction(
  '[Growth Slots Modal] Modal changed',
  props<{ growthSlotProfileId: number; airport: string }>()
);
