import {
  AirportViewSlotDto,
  CurfewAirportDto,
  GrowthSlotsDetailsAirport,
  SlotProfile
} from '@features/slots/slots.models';
import { SelectItem } from '@shared/models/select-item.interface';

export const growthSlotsTabs: SelectItem<SlotsRoute>[] = [
  { label: 'Slots', value: 'slots' },
  { label: 'Curfews', value: 'curfews' },
  { label: 'Curfews (Additional Constraints)', value: 'curfews-additional-constraints' }
];

export type SlotsRoute = 'airport' | 'curfews' | 'curfews-additional-constraints' | 'slots';

export interface GrowthSlotsState {
  selectedTab: SelectItem<SlotsRoute>;
  slotName: string;
  slotProfiles: SlotProfile[];
  isLocalTimeZone: boolean;
  selectedAirportFromAirports: string | undefined;
  dropdownFilterAirports: string[] | undefined;
  searchSwitch: string;
  airportSlot: AirportViewSlotDto | undefined;
  airportCurfews: CurfewAirportDto[] | undefined;
  airportCurfewDraft: CurfewAirportDto | undefined; // not saved curfew under edit
  slotsDetailsAirports: GrowthSlotsDetailsAirport[];
  slotsDetailsAirportRowDraft: string | undefined;
}

export const growthSlotsInitialState: GrowthSlotsState = {
  selectedTab: growthSlotsTabs[0],
  slotName: '',
  slotProfiles: undefined,
  isLocalTimeZone: true,
  selectedAirportFromAirports: undefined,
  dropdownFilterAirports: [],
  searchSwitch: 'AND',
  airportSlot: undefined,
  airportCurfews: undefined,
  airportCurfewDraft: undefined,
  slotsDetailsAirports: undefined,
  slotsDetailsAirportRowDraft: undefined
};
