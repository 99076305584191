import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddCurfew,
  AirportViewSlotDto,
  CurfewDto,
  CurfewAirportDto,
  CurfewOverride,
  GrowthSlotsDetailsAirport,
  SlotProfile
} from '../slots.models';
import { HttpClient } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { Params } from '@angular/router';
import { DropdownFilter } from '@shared/components/flyinglines/flyinglines.models';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class SlotsHttpService {
  private rootEndPoint = 'growthslot';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  // SLOTS
  getGrowthSlotsProfiles(season: SeasonCode): Observable<SlotProfile[]> {
    return this.http.get<SlotProfile[]>(`${this.apiUrl}/${season}/${this.rootEndPoint}`);
  }
  addSlot(name: string, season: string): Observable<SlotProfile> {
    const params = { name, season };
    return this.http.post<SlotProfile>(`${this.apiUrl}/${season}/${this.rootEndPoint}`, params);
  }
  cloneSlot(sourceSlotId: number, name: string, season: string): Observable<SlotProfile> {
    const params = { name, season };
    return this.http.post<SlotProfile>(`${this.apiUrl}/${season}/${this.rootEndPoint}/${sourceSlotId}`, params);
  }

  updateGrowthSlot(
    season: SeasonCode,
    profileId: number,
    airportCode: string,
    slot: Partial<AddCurfew>
  ): Observable<void> {
    return this.http.patch<void>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${profileId}/airport/${airportCode}/hour`,
      slot
    );
  }

  deleteSlot(slotId: number, season: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${season}/${this.rootEndPoint}/${slotId}`);
  }

  // GROWTH SLOTS
  getGrowthSlotsAirport(profileId: number, airportCode: string, season: SeasonCode): Observable<AirportViewSlotDto> {
    return this.http.get<AirportViewSlotDto>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${profileId}/airport/${airportCode}`,
      {
        responseType: 'json'
      }
    );
  }

  // CURFEWS

  getCurfewAirports(
    growthSlotprofileId: number,
    dropdownFilter: DropdownFilter,
    season: SeasonCode
  ): Observable<CurfewAirportDto[]> {
    const params: Params = {
      airports: dropdownFilter.options
        .filter((option) => option.type === 'AIRPORT')
        .map((option) => option.code)
        .join(','),
      operator: dropdownFilter.operator,
      season
    };
    return this.http.get<CurfewAirportDto[]>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${growthSlotprofileId}/curfews`,
      { params }
    );
  }

  updateCurfew(
    profileId: number,
    airportCode: string,
    season: SeasonCode,
    curfewObject: Partial<AddCurfew>
  ): Observable<AirportViewSlotDto> {
    return this.http.patch<AirportViewSlotDto>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${profileId}/airport/${airportCode}`,
      curfewObject
    );
  }

  updateCurfewBulk(
    growth_slot_id: number,
    season: SeasonCode,
    curfewObject: Partial<AddCurfew>
  ): Observable<CurfewDto> {
    return this.http.patch<CurfewDto>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${growth_slot_id}/airport`,
      curfewObject
    );
  }

  // CURFEW OVERRIDES

  getCurfewOverrides(season: SeasonCode, profile_id: number): Observable<Array<CurfewOverride>> {
    const params: Params = {
      airports: [],
      operator: 'AND',
      season
    };
    return this.http.get<Array<CurfewOverride>>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${profile_id}/curfews/override`,
      { params }
    );
  }

  createCurfewOverride(season: SeasonCode, curfewObject: CurfewOverride): Observable<Array<CurfewOverride>> {
    return this.http.post<Array<CurfewOverride>>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${curfewObject.profile_id}/curfews/override`,
      curfewObject
    );
  }

  updateCurfewOverride(season: SeasonCode, curfewObject: CurfewOverride): Observable<Array<CurfewOverride>> {
    return this.http.put<Array<CurfewOverride>>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${curfewObject.profile_id}/curfews/override/${curfewObject.id}`,
      curfewObject
    );
  }

  deleteCurfewOverride(season: SeasonCode, curfewObject: CurfewOverride): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${curfewObject.profile_id}/curfews/override/${curfewObject.id}`
    );
  }

  // SLOTS

  getGrowthSlotsSlots(
    profileId: number,
    dropdownFilter: DropdownFilter,
    season: SeasonCode
  ): Observable<GrowthSlotsDetailsAirport[]> {
    const params: Params = {
      airports: dropdownFilter.options.map((option) => option.code),
      operator: dropdownFilter.operator,
      season
    };
    return this.http.get<GrowthSlotsDetailsAirport[]>(
      `${this.apiUrl}/${season}/${this.rootEndPoint}/${profileId}/slots`,
      { params }
    );
  }
}
